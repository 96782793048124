var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"margin-padding-zero"},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('PageHeader',{attrs:{"header":_vm.pageHeader},scopedSlots:_vm._u([{key:"mainButton",fn:function(){return [_c('v-row',{staticClass:"justify-end mb-5 mr-12"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mr-2 text-capitalize",attrs:{"color":"info"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-history")]),_vm._v(" "+_vm._s(_vm.$t("history"))+" ")],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('UploadDialog')],1)],1)]},proxy:true}])}),_c('v-row',{staticClass:"mt-5 ml-2"},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{ref:"menuDate",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ml-5 mr-5",attrs:{"outlined":"","label":_vm.$t('month'),"prepend-icon":"","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"type":'month',"no-title":"","scrollable":""},on:{"input":_vm.updateMonth},model:{value:(_vm.originalMonth),callback:function ($$v) {_vm.originalMonth=$$v},expression:"originalMonth"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"type":"year","valueType":"format","placeholder":_vm.$t('year'),"attach":".mx-input"},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.companiesWithCodeAndSocialName,"label":_vm.$t('codeCompany'),"outlined":"","item-text":"codeAndSocialName","item-value":"companyId","filter":_vm.customFilter,"clearable":true,"return-object":true},on:{"update:search-input":function (val) { return (_vm.searchCompany = val); }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{key:item.id},[_vm._v(" "+_vm._s(item.codeAndSocialName)+" ")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.codeAndSocialName)+" ")])]}}]),model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{staticClass:"align-end",attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"primary",on:{"click":_vm.search}},[_vm._v(_vm._s(_vm.$t("search"))+" ")])],1)],1),_c('div',{staticClass:"tabs-report"},[_c('div',{staticClass:"ml-12"},[_c('v-tabs',{staticClass:"tabs-report-container mb-5",attrs:{"grow":"","background-color":"grey lighten-4"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,index){return _c('v-tab',{key:index,staticClass:"tabs-report-item",class:{ 'active-tab': _vm.tab === index }},[(_vm.tab === index)?_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-check")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")],1)}),1)],1),_c('div',{staticClass:"align-buttons"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize",attrs:{"small":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-chevron-down")]),_vm._v(" "+_vm._s(_vm.$t("download"))+" ")],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"disabled":_vm.disabledDownloads},on:{"click":_vm.createPDF}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("pdf")))])],1),_c('v-list-item',{attrs:{"disabled":_vm.disabledDownloads},on:{"click":function($event){return _vm.downloadExcel(_vm.list)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("csv")))])],1)],1)],1)],1)]),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('AllData',{attrs:{"listSearch":_vm.listSearch},on:{"updated-list":_vm.updatedList,"table-id":_vm.tableId}})],1),_c('v-tab-item',[_c('MissingData',{attrs:{"listSearch":_vm.listSearch},on:{"updated-list":_vm.updatedList,"table-id":_vm.tableId}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }














































































































































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";

import { HeaderItem } from "../../../types/HeaderItem";
import { TranslateResult } from "vue-i18n";
import { itemsPerPage } from "../../../types/itemsPerPage";
@Component
export default class AllData extends Request {
  @Prop() listSearch: any;
  header: HeaderItem[] = [
    { text: `${translate("code")}`, align: "center", value: "code" },
    { text: `${translate("month")}`, align: "center", value: "month" },
    { text: `${translate("year")}`, align: "center", value: "year" },
    { text: `${translate("marketing")}`, align: "center", value: "marketing" },
    { text: `${translate("service")}`, align: "center", value: "service" },
    { text: `${translate("utility")}`, align: "center", value: "utility" },
    {
      text: `${translate("authentication")}`,
      align: "center",
      value: "authentication",
    },
    { text: `${translate("all")}`, align: "start", value: "all" },
    { text: "", align: "center", value: "data-table-expand" },
  ];
  tableMissingId = "missingTable";
  expanded = [];
  singleExpand = false;
  itemsPerPage: number = 10;
  list: any = [];
  itemsPerPageList: itemsPerPage[] = [
    { id: 1, value: 10 },
    { id: 2, value: 50 },
    { id: 3, value: 100 },
  ];
  page: number = 1;
  pageCount: number = 1;
  search: string = "";
  headerDetails: HeaderItem[] = [
    { text: `${translate("direction")}`, align: "center", value: "direction" },
    { text: `${translate("meta")}`, align: "center", value: "meta" },
    { text: `${translate("chatXIa")}`, align: "center", value: "chatXIa" },
    {
      text: `${translate("comparative")}`,
      align: "center",
      value: "comparative",
    },
  ];
  reportCompare: any = {};
  reportMeta: any = {};
  reportChatBots: any = {};
  defineColor(value) {
    return value === true ? "#ff0000" : "#32906b";
  }
  async loadData() {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();

    const params = new URLSearchParams();
    params.append("month", month);
    params.append("year", year);
    params.append("take", this.itemsPerPage.toString());
    params.append("skip", "1");
    params.append("orderByParam", "code");
    params.append("orderOrientation", "DESC");
    params.append("isFull", "false");

    const result = await this.get(
      `${this.paths.companyReporttPath}?${params.toString()}`
    );
    this.list = result.result;
    this.$emit("updated-list", result.result);
    this.$emit("table-id", this.tableMissingId);
  }
  mounted() {
    this.loadData();
  }
  translateMonth = (month: number): TranslateResult => {
    return this.monthTranslations[month] || "";
  };
  async loadDetails({ item }) {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString();

    const params = new URLSearchParams();
    params.append("month", month);
    params.append("year", year);
    params.append("take", this.itemsPerPage.toString());
    params.append("code", item?.code);

    const result = await this.get(
      `${this.paths.companyReportDetailsPath}?${params.toString()}`
    );
    this.reportCompare = result.reportCompare;
    this.reportMeta = result.reportMeta;
    this.reportChatBots = result.reportChatBots;
  }
  monthTranslations: { [key: number]: TranslateResult } = {
    1: translate("january"),
    2: translate("february"),
    3: translate("march"),
    4: translate("april"),
    5: translate("may"),
    6: translate("june"),
    7: translate("july"),
    8: translate("august"),
    9: translate("september"),
    10: translate("october"),
    11: translate("november"),
    12: translate("december"),
  };
  get validatedMissingDataList() {
    const dataList = this.listSearch.length > 0 ? this.listSearch : this.list;
    return dataList.map((item) => {
      return {
        ...item,
        marketing: item.marketing ?? "--",
        service: item.service ?? "--",
        utility: item.utility ?? "--",
        code: item.code ?? "--",
        month: item.month ? this.translateMonth(item.month) : "--",
        year: item.year ?? "--",
        authentication: item.authentication ?? "--",
      };
    });
  }
}

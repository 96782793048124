





















































































































































import { Component } from "vue-property-decorator";
import Request from "../../../services/request";
import { translate } from "../../../plugins/i18n/index";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { mapGetters } from "vuex";

@Component({
  components: {
    DatePicker,
  },
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class UploadDialog extends Request {
  dialog = false;
  fileType: string = "";
  menuDate: boolean = false;
  date: string = "";
  originalMonth: string = "";
  attachmentFile: File | null = null;
  year: string = "";
  getUser: any;
  user: any = {};
  isLoading: boolean = false;
  fileTypeList: any = [
    { id: 1, title: translate("attendency"), value: "A" },
    { id: 2, title: translate("conversation"), value: "C" },
  ];
  mounted() {
    this.user = this.getUser();
  }
  updateMonth(value) {
    this.date = moment(value).format("MM");
  }

  handleFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.[0];
    if (file) {
      this.attachmentFile = file;
      console.log("Arquivo selecionado:", file);
    }
  }

  handleFileDrop(event: DragEvent) {
    const file = event.dataTransfer?.files[0];
    if (file) {
      this.attachmentFile = file;
    }
  }
  updateYear(value) {
    this.year = moment(value).format("YYYY");
  }

  clearFile() {
    this.attachmentFile = null;
    this.date = "";
  }
  createAndUploadExcel(formData: FormData) {
    this.post(this.paths.uploadReportPath, formData);
  }
  readFileAsBase64(file: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
  async attachmentFileChange() {
    this.isLoading = true;
    if (this.attachmentFile) {
      const base64File = await this.readFileAsBase64(this.attachmentFile);

      const base64Filter = base64File.split(";base64,").pop();
      const base64WithPrefix = `data:application/xlsx;base64,${base64Filter}`;
      const formData = new FormData();
      formData.append("year", this.year);
      formData.append("month", this.date);
      formData.append("file", base64WithPrefix);
      formData.append("fileType", this.fileType);
      formData.append("fileName", this.attachmentFile.name);
      formData.append("editorUserId", this.user.id);

      await this.createAndUploadExcel(formData);
      this.dialog = false;
      this.isLoading = false;
    }
  }

  get saveDisabled() {
    return !this.year || !this.originalMonth || !this.attachmentFile;
  }
}
























































































import { Component, Prop } from "vue-property-decorator";
import Request from "../../services/request";
import { translate } from "../../plugins/i18n/index";
import { mapGetters } from "vuex";
@Component({
  methods: {
    ...mapGetters({
      getUser: "auth/getUser",
    }),
  },
})
export default class DeleteCompanyProductPlan extends Request {
  @Prop() companyProductPlanId: number;
  @Prop() entity: any;
  @Prop() companyId: any;

  dialog: boolean = false;
  localEntity = {};
  observation: string = "";
  block = null;
  getUser: any;
  user: any = {};
  blockList = [
    { id: 1, value: "BF", reason: `${translate("financialBlockage")}` },
    { id: 2, value: "BA", reason: `${translate("administrativeBlocking")}` },
    { id: 3, value: "SN", reason: `${translate("notActivated")}` },
  ];
  unblock = null;
  unblockList = [
    { id: 1, value: "PR", reason: `${translate("PaymentMade")}` },
    { id: 2, value: "PA", reason: `${translate("scheduledPayment")}` },
    { id: 3, value: "SA", reason: `${translate("activeService")}` },
  ];
  async blockUnblock() {
    this.localEntity = {
      observation: this.observation,
      companyId: this.companyId,
      productId: this.entity.id,
      scope: this.entity.scope,
      editorUserId: this.user.id,
      block: !this.entity.companyProductPlan.isBlocked,
      reason: this.block ? this.block : this.unblock,
    };
    const result = await this.post(this.paths.omniBlockPath, this.localEntity);

    this.entity.companyProductPlan.isBlocked =
      !this.entity.companyProductPlan.isBlocked;
    this.observation = "";

    this.dialog = false;
    this.localEntity = {};
    this.block = null;
    this.unblock = null;
    this.observation = "";

    return result;
  }
  mounted() {
    this.user = this.getUser();
  }
  get buttonIcons() {
    return this.entity.companyProductPlan.isBlocked === true
      ? {
          icon: "mdi-lock-outline",
          title: `${translate("unblock")}`,
          color: "rgb(249 0 0)",
        }
      : {
          icon: "mdi-lock-open-outline",
          title: `${translate("block")}`,

          color: "rgb(76, 175, 80)",
        };
  }
  get isBlocked() {
    return !this.block;
  }
  get isUnblocked() {
    return !this.unblock;
  }
  get saveDisabled() {
    return !this.block ? this.isUnblocked : this.isBlocked;
  }
}

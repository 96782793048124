








































































































































































import { Component, Watch } from "vue-property-decorator";
import {
  PageHeader,
  BaseBreadcrumb,
} from "@matrixbrasil/matrix-lib-utils-frontend/src/components";
import Request from "../../services/request";
import { page } from "../../types/Page";
import { translate } from "../../plugins/i18n/index";
import { Breadcrumb } from "../../types/Breadcrumb";
import { TPageHeader } from "@matrixbrasil/matrix-lib-utils-frontend/src/types/PageHeader";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { TCompanyNotification } from "../../types/Notification/TCompanyNotification";
import UploadDialog from "./components/UploadDialog.vue";
import AllData from "./components/AllData.vue";
import MissingData from "./components/MissingData.vue";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "jspdf-autotable";
@Component({
  components: {
    PageHeader,
    BaseBreadcrumb,
    DatePicker,
    UploadDialog,
    AllData,
    MissingData,
  },
})
export default class CompanyReportAttendency extends Request {
  page: page = {
    title: `${translate("companyReportAttendency")}`,
    icon: null,
  };
  breadcrumbs: Breadcrumb[] = [
    {
      text: `${translate("metaXchatbot")}`,
      disabled: true,
    },
  ];
  items = [
    { id: 1, name: `${translate("all")}` },
    { id: 2, name: `${translate("missingData")}` },
  ];

  tab: number = null;
  originalMonth: string = "";
  date: string = "";
  year: string = "";
  menuDate: boolean = false;
  pageHeader: TPageHeader = {
    mainIcon: "mdi-chart-areaspline",
    titlePage: `${translate("companyReportAttendency")}`,
    buttonHeader: "true",
    buttonIcon: "mdi-plus",
    method: "openInputNew",
    crudDetail: "false",
  };
  list: any = [];
  tableIdPdf: string = "";
  entriesCompany: TCompanyNotification[] = [];
  itemsPerPage: number = 10;
  company: any = null;
  searchCompany: string = "";
  listSearch: any = [];
  async mounted() {
    const companyList = await this.get(
      this.paths.companyPagePath + `?take=${this.itemsPerPage}&skip=${1}`
    );
    this.entriesCompany = companyList.result;
  }
  tableId(id) {
    this.tableIdPdf = id;
  }
  updatedList(list) {
    this.list = list;
  }
  updateMonth(value) {
    this.date = moment(value).format("MM");
  }
  downloadCsv(list) {
    return list;
  }
  async downloadExcel(listToDownload) {
    this.list = await this.downloadCsv(listToDownload);
    if (this.list.length > 0) {
      let result, keys;

      if (this.list === null || (!this.list && !this.list.length)) {
        return this.$message?.successMessage(translate("downloadReady"));
      }

      const headersToExport = [
        "code",
        "month",
        "year",
        "marketing",
        "service",
        "authentication",
      ];

      keys = Object.keys(this.list[0]).filter((key) =>
        headersToExport.includes(key)
      );

      result = "";
      result += keys
        .map((key) => {
          return key === "isBlocked" ? translate("status") : key;
        })
        .map((key) => translate(key))
        .join(",");
      result += "\n";

      this.list.forEach((item) => {
        result += keys
          .map((key) => {
            const cellValue =
              key === "isBlocked"
                ? item[key]
                  ? translate("chip.isBlocked")
                  : translate("active")
                : item[key];

            return typeof cellValue === "string" && cellValue.includes(",")
              ? `"${cellValue}"`
              : cellValue;
          })
          .join(",");
        result += "\n";
      });

      var hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(result);
      hiddenElement.target = "_blank";

      hiddenElement.download = "relatório.csv";
      hiddenElement.click();
    }
  }
  createPDF() {
    var pdf = new jsPDF("p", "mm", "a4");
    var element = document.getElementById(this.tableIdPdf);

    html2canvas(element).then((canvas) => {
      var image = canvas.toDataURL("image/png");
      pdf.addImage(
        image,
        "JPEG",
        1,
        1,
        canvas.width * 0.12,
        canvas.height * 0.12,
        "a",
        "FAST"
      );
      pdf.save("dados_exportados" + ".pdf");
    });
  }
  async search() {
    const params = new URLSearchParams();
    if (this.date) params.append("month", this.date);
    if (this.year) params.append("year", this.year);
    params.append("take", this.itemsPerPage.toString());
    params.append("skip", "1");
    if (this.company?.code) params.append("searchParam", this.company.code);
    params.append("orderByParam", "code");
    params.append("orderOrientation", "ASC");
    params.append("isFull", "true");
    const result = await this.get(
      `${this.paths.companyReporttPath}?${params.toString()}`
    );
    this.listSearch = result.result;
  }
  get companiesWithCodeAndSocialName() {
    return this.entriesCompany.map((company) => ({
      ...company,
      codeAndSocialName: `${company.code} - ${company.socialName}`,
    }));
  }
  customFilter(item, queryText, itemText) {
    item;
    const searchText = queryText.toLowerCase();
    const itemValue = itemText.toLowerCase();
    return itemValue.includes(searchText);
  }
  @Watch("searchCompany")
  async searchCompanies(val) {
    if (val && val.length >= 3) {
      const list = await this.get(
        this.paths.companyPagePath +
          `?take=${this.itemsPerPage}&skip=${1}&searchValue=${val}`
      );
      this.entriesCompany = [...this.entriesCompany, ...list.result];
    }
  }

  get disabledDownloads() {
    return this.list.length <= 0;
  }
}
